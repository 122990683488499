import styled from "styled-components";
import FacilitiesCard from "../components/FacilitiesCard";
import PageBanner from "../components/PageBanner";
import ScrollToTop from '../hooks/ScrollToTop';


//images
import BACK from "../images/facility-bcak.jpeg";
import Workshop from "../images/Facilities/1.jpeg";
import Learning from "../images/Facilities/2.jpeg";
import Cultural from "../images/Facilities/3.jpeg";
import Splash from "../images/Facilities/4.jpeg";
import Competition from "../images/Facilities/5.jpeg";
import Theme from "../images/Facilities/6.jpeg";


const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
`;

function Facilities() {
  ScrollToTop();
  return (
    <Container>
      <PageBanner img={BACK} pagename={"Facilities"} />

      <CardContainer>
        <FacilitiesCard img={Workshop} title={"Workshop"} />
        <FacilitiesCard title={"Learning Activity"} img={Learning} />
        <FacilitiesCard title={"Cultural Activity"} img={Cultural} />
        <FacilitiesCard title={"Splash Water pool"} img={Splash} />
        <FacilitiesCard title={"Competition Programs"} img={Competition} />
        <FacilitiesCard title={"Theme driven class room walls"} img={Theme} />
      </CardContainer>
    </Container>
  );
}

export default Facilities;
