import styled from "styled-components";

//images
import BACK from "../images/bg-team-section.jpg"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  background-image: url(${BACK});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 1rem auto;

  //for mobile
  @media (max-width: 480px) {
    height: 600px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
  gap: 1rem;

  //for mobile
  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }
`

const Left = styled.div`
  display: flex;
  width: 25%;
  height: 100%;

  //for mobile
  @media (max-width: 480px) {
    width: 70%;
    height: 50%;
  }
`;


const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Right = styled.div` 
  font-family: "fredoka one", sans-serif;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  gap: 10px;
  overflow-y: scroll;

  //for mobile
  @media (max-width: 480px) {
    width: 90%;
  }
`;

const Title = styled.h1`
  font-family: "Fredoka", sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #271344;
  margin: 0;
  padding: 0;
`;


const DescriptionContainer = styled.p`
  font-family: "Fredoka", sans-serif;
  font-size: 16px;
  color: #9f9da1;
  margin: 0;
  padding: 0;
  p{
    margin: 10px;
    padding: 0;
  
  }
`;

function TeacherInfoCard({ src, data }) {
  return (
    <>
      <Container>
        <MainContainer>
          <Left>
            <ImageContainer>
              <Image src={src} />
            </ImageContainer>
          </Left>

          <Right>
            <Title>{data["title"]}</Title>
            <DescriptionContainer>
              {data["desc"].map((desc, index) => (
                <p key={index}>{desc}</p>
              ))
              }
            </DescriptionContainer>
          </Right>
        </MainContainer>
      </Container>
    </>
  );
}

export default TeacherInfoCard;
