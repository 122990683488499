import styled from 'styled-components'

//svg
import Phone from '../icons/Phone.svg'
import Map from '../icons/Map.svg'
import Facebook from '../icons/Facebook.svg'
import Instagram from '../icons/Insta.svg'

const Container = styled.div`
  display: flex;
  background-color: #0177aa;
  width: 100vw;
  height: 70px;
  justify-content: center;
  align-items: center;
  //  //breakpoint for tablet
  @media (max-width: 768px) {
    height: 60px;
  }
  //  //breakpoint for mobile
  @media (max-width: 480px) {
    height: 100px;
    width: 100%;
  }
`

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 80%;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #0177aa;
  gap: 20px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`

const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    gap: 5px;
  }
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
`

const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: white;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 12px;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #0177aa;
`

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  //breakpoint for mobile
  @media (max-width: 480px) {
    gap: 20px;
    flex-direction: column;
  }
`

const SocialIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  a{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`

const Social = styled.img`
  width: 20px;
  height: 20px;
`




function Address() {
  return (
    <Container>
      <MainContainer>
        <Left>
          <ItemContainer>
            <IconContainer>
              <Icon src={Phone} />
            </IconContainer>
            <Text>+91 9650674928</Text>
            <Text>+91 9818609552</Text>
          </ItemContainer>

          <ItemContainer>
          <IconContainer>
              <Icon src={Map} />
            </IconContainer>
            <Text>RZ-108A,Street no.3,Raghu Nagar,Shiv block,Pankha road,New Delhi-45</Text>
          </ItemContainer>
        </Left>
        <Right>
          <SocialContainer>
            <SocialIcon>
              <a href="https://www.facebook.com/officialnbbschool">
                <Social src={Facebook} />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a href="https://www.instagram.com/newbloomingbudsschool/">
                <Social src={Instagram} />
              </a>
            </SocialIcon>
          </SocialContainer>
        </Right>
      </MainContainer>
    </Container>
  )
}

export default Address