import styled, { keyframes } from 'styled-components';

// Define the spin animation
const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 340px;
  border-radius: 5px;
  border-bottom: 10px solid #f2f2f2;
  gap: 0px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    width: calc(100vw - 10px);
  }
`;

const CardImageContainer = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
  margin: 30px;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    width: 85px;
    height: 85px;
    border-radius: 50%;
    border: 3px dashed #f2f2f2;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${spin} 10s infinite linear;
    animation-play-state: paused; /* Pause the animation by default */
  }

  &:hover::before {
    animation-play-state: running; /* Start the animation on hover */
  }
`;

const CardImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0px 0px 0px 30px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-around;
  margin: 0px 30px 0px 30px;
  p{
    font-size: 14px;
    color: #000000;
  }
`;

const Button = styled.a`
  color: black;
  font-size: 16px;
  width: 100px;
  font-weight: 500;
  cursor: pointer;
  transition: all 300ms linear;
  &:hover {
    color: #ffffff;
    text-decoration: underline;
  }
`


// Card component
function Card({ color, borderColor, icon, title, info }) {
  return (
    <Container style={{ backgroundColor: color, borderColor: borderColor }}>
      <CardImageContainer>
        <CardImage src={icon} />
      </CardImageContainer>
      <CardTitle>{title}</CardTitle>
      <CardContent>
        <p>
          {info}
        </p>

      <Button>Read More</Button>

      </CardContent>
    </Container>
  );
}

export default Card;
