import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'

//Images
import CurveImage from '../images/curve.jpeg'
import BEE from '../images/bee.png'
import GRASS from "../images/grass.webp"
import LandscapeImage from '../images/landscape.png'

//Icons
import STUDENT from "../icons/student.png"
import TEACHER from "../icons/woman-face.png"
import BUILDING from "../icons/building.png"
import BOOK from "../icons/books-stack.png"

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const Curve = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50%;
  background-color: #FBF9F3;
  flex-direction: column;
  align-items: center;
`

const CurveTop = styled.div`
  width: 100%;
  height: 100px;
  background-image: url(${CurveImage});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
`

const CurveMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 80%;
  height: 250px;
 
  //breakpoint for mobile
  @media (max-width: 480px) {
    flex-direction: column;
    height: 100%;
    padding-bottom: 10px;
  }

`

const Part = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const Heading = styled.h1`
  font-family: "Schoolbell", cursive;
  color: #fe4880;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 24px;
  }
`

const HeadingImage = styled.img`
  width: 100px;
  height: 40px;
  object-fit: contain;
  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 80px;
    height: 30px;
  }
`

const Title = styled.h1` 
  font-size: 40px;
  color: #000000;
  font-weight: 300;
  margin: 0;
  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 30px;
  }
`

const Desc = styled.p`
  font-size: 16px;
  color: #000000;
  font-weight: 300;
  text-align: center;
  margin: 0px;
  line-height: 30px;
  letter-spacing: 1px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 14px;
  }
 `

const Button = styled.button`
  padding: 10px 10px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #fe4880;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 350ms;
  &:hover {
    background-color: #ff6665;
  }

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 150px;
    height: 40px;
    font-size: 14px;
  }
`

const Grassland = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  background-image: url(${GRASS});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: contain;
  
  //breakpoint for mobile
  @media (max-width: 480px) {
    height: 100%;
  }
`

const GrasslandBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  width: 100%;
  height: 100%;
  background-image: url(${LandscapeImage});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;

  //breakpoint for mobile
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  width: 150px;
  height: 200px;
  background-color: transparent;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 100px;
    height: 150px;
  }
`

const CardImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 2px dotted #ffffff;
  border-radius: 10px;
  background-color: #98b941;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 50px;
    height: 50px;
  }
`


const CardImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`

const CardTitle = styled.h1`
  font-size: 36px;
  color: #333030;
  font-weight: 500;
  margin: 10px 0px 0px 0px;
  
  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 24px;
  }
`

const CardDesc = styled.p`
  font-size: 16px;
  color: #333030;
  font-weight: 300;
  text-align: center;
  margin: 0;
  line-height: 20px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 14px;
  }
`





function HomeInfo() {

  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate("/facilities")
  }


  return (
    <Container>
      <Curve>
        <CurveTop />
        <CurveMain>
          <Part>
            <HeadingContainer>
              <Heading>LEARNING WITH PLAYING</Heading>
              <HeadingImage src={BEE} />
            </HeadingContainer>

            <Title>
              Our Classes
            </Title>

            <Desc>
              Explore the State-of-the-Art Facilities Designed to Spark the Imagination of our schoolers at New Blooming Buds School
            </Desc>
          </Part>

          <Part>
            <Button onClick={handleNavigate}>
              View All Facilities
            </Button>
          </Part>
        </CurveMain>
      </Curve>

      <Grassland>
        <GrasslandBody>
          <Card>
            <CardImageContainer>
              <CardImage src={STUDENT} />
            </CardImageContainer>

            <CardTitle>
              200+
            </CardTitle>
            <CardDesc>
              Students  
            </CardDesc>
          </Card>

          <Card>
            <CardImageContainer>
              <CardImage src={BOOK} />
            </CardImageContainer>

            <CardTitle>
              21 Yrs
            </CardTitle>
            <CardDesc>
              Years  
            </CardDesc> 
          </Card>

          <Card>
            <CardImageContainer>
              <CardImage src={BUILDING} />
            </CardImageContainer>

            <CardTitle>
              1
            </CardTitle>
            <CardDesc>
              Branch  
            </CardDesc> 
          </Card>

          <Card>
            <CardImageContainer>
              <CardImage src={TEACHER} />
            </CardImageContainer>
            <CardTitle>
              20
            </CardTitle>
            <CardDesc>
              Teachers  
            </CardDesc> 
          </Card>
        </GrasslandBody>
      </Grassland>
    </Container>
  )
}

export default HomeInfo