import styled from 'styled-components'


const Container = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
  background-image: ${props => `url(${props.img})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: white;
`


const Header = styled.h1`
  font-size: 48px;
  font-weight: 800;
  margin: 0;
`

const Navigation = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  font-family: 'schoolbell';
  word-spacing: 10px;
  color: yellow;
`


function PageBanner({pagename, img}) {
  return (
    <Container img={img}>
      <Overlay>
        <Header>
          {pagename}
        </Header>
        <Navigation>
          Home  {' > '}  {pagename}
        </Navigation>
      </Overlay>
    </Container>
  )
}

export default PageBanner