import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'



const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;  
  background-color: #fef100;
  width: 100vw;
  height: 100%;
`


const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Logo = styled.img`
  height: 100px;
  width: 100px;
`

const TopMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
`

const BottomMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ff0000;
  padding: 10px 0px;
`

const MenuContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;  
` 

const MenuItem = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: white;
  background-color: #ff0000;
  cursor: pointer;
  padding:  2px;
`

function MobileNavbar() {

  const navigate = useNavigate();

  const handleMenu = (path) => {
    navigate(path);
  }

  return (
    <Container>
      <TopMenuContainer>
        <LogoContainer>
          <Logo src= {'https://res.cloudinary.com/dtpb5qjjf/image/upload/f_auto,q_auto:low/v1710040067/logo.5ecc2314d76c5bca63fd_tvslqr.png'} />
        </LogoContainer>
      </TopMenuContainer>

      <BottomMenuContainer>
        <MenuContainer>
          <MenuItem onClick={() => handleMenu("/")} >Home</MenuItem>
          <MenuItem onClick={() => handleMenu("/about-us")} >About</MenuItem>
          <MenuItem onClick={() => handleMenu("/facilities")} >Facilities</MenuItem>
          <MenuItem onClick={() => handleMenu("/gallery")} >Gallery</MenuItem>
          <MenuItem onClick={() => handleMenu("/teacher")} >Teacher</MenuItem>
          <MenuItem onClick={() => handleMenu("/enroll")} >Enroll</MenuItem>
        </MenuContainer>
      </BottomMenuContainer>

    </Container>
  )
}

export default MobileNavbar
