import { useEffect, useState } from "react"
import styled from "styled-components"
import moment from 'moment';
import axios from 'axios'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
  width: 100%;
  background: -webkit-linear-gradient(left, #25c481, #25b7c4);
  background: linear-gradient(to right, #25c481, #25b7c4);
  color: #fff;
`

const Table = styled.table`
  width:100%;
  table-layout: fixed;
`

const TableHeader = styled.div`
  background-color: rgba(255,255,255,0.3);  
`

const TableContent = styled.div`
    height:100vh;
    overflow-x:auto;
    margin-top: 0px;
    border: 1px solid rgba(255,255,255,0.3);
`

const TH = styled.th`
    padding: 20px 15px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
`

const TD = styled.td`
  padding: 15px;
  text-align: center;
  vertical-align:middle;
  font-weight: 300;
  font-size: 12px;
  color: #fff;
  border-bottom: solid 1px rgba(255,255,255,0.1); 
`


function Admin() {

  const [data, setData] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get('https://bl-ljv9.onrender.com/api/form')
      setData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }
  , [])




  return (
    <Container>
      <TableHeader>
        <Table cellPadding="0" cellSpacing="0" style={{ border: "0" }}>
          <thead>
            <tr>
              <TH>Parent’s name</TH>
              <TH>Address</TH>
              <TH>Mobile No</TH>
              <TH>Email</TH>
              <TH>Child Name</TH>
              <TH>Child Age</TH>
              <TH>Class</TH>
              <TH>Query</TH>
              <TH>Time</TH>
            </tr>
          </thead>
        </Table>
      </TableHeader>
      {data.length > 0 && (
           <TableContent>
           <Table cellPadding="0" cellSpacing="0" style={{ border: "0" }}>
             <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <TD>{item.parentName}</TD>
                  <TD>{item.address}</TD>
                  <TD>{item.mobile}</TD>
                  <TD>{item.email}</TD>
                  <TD>{item.childName}</TD>
                  <TD>{item.childAge}</TD>
                  <TD>{item.childClass}</TD>
                  <TD>{item.query}</TD>
                  <TD>{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</TD>
                </tr>
              ))} 

             </tbody>
           </Table>
         </TableContent>
        )}
    </Container>
  )
}

export default Admin