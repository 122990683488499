import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin: 10px;
  padding: 10px;
  border: 2px solid #f2f2f2;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 300px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
`



function Photo({link}) {
  return (
    <Container>
      <Image src={link} />
    </Container>
  )
}

export default Photo