import styled, { keyframes } from 'styled-components'
import ScrollToTop from '../hooks/ScrollToTop';


//components
import PageBanner from '../components/PageBanner'

//images
import AboutImage from '../images/about-image.jpg'
import LandscapeImage from '../images/landscape.png'
import BACK from '../images/about-back.jpeg';

//Icons
import STUDENT from "../icons/student.png"
import TEACHER from "../icons/woman-face.png"
import BUILDING from "../icons/building.png"
import BOOK from "../icons/books-stack.png"
import AboutBanner from '../components/AboutBanner'


// Define the spin animation
const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Main = styled.div`
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 80%;
  height: 100%;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  flex: 1;
  height: 100%;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 500px;
  overflow: hidden;
  position: relative;
  border: 5px solid #dcdcdc;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px #dcdcdc;
  animation: moveRightLeft 2s infinite alternate;
  
  @keyframes moveRightLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-30px);
    }
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  flex: 1;
  height: 100%;
`

const Heading = styled.h1`
  font-family: "Fredoka", sans-serif;
  color: #271343;
  font-size: 36px;
  font-weight: 800;
  margin: 0px;
`

const Description = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #777;
  line-height: 1.6;
`

const BasicHeading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin: 0px;
`

const ExtraContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 15px;
  flex: 1;
  height: 100%;
`

const Button = styled.button`
  padding: 10px 10px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #fe4880;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 350ms;
  &:hover {
    background-color: #ff6665;
  }
`


//Grassland
const Grassland = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
`

const GrasslandBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  width: 100%;
  height: 100%;
  background-image: url(${LandscapeImage});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
`


const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px;
  cursor: pointer;
  color: white;

  &::before {
    position: absolute;
    content: '';
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: 3px dashed #f2f2f2;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${spin} 10s infinite linear;
    animation-play-state: paused; /* Pause the animation by default */
  }

  &:hover::before {
    animation-play-state: running; /* Start the animation on hover */
  }
`;


const CardImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
`

const CardImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`

const CardTitle = styled.h1`
  font-size: 36px;
  color: #ffffff;
  font-weight: 600;
  margin: 10px 0px 0px 0px;

`

const CardDesc = styled.p`
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin: 0;
  line-height: 20px;
`


function About() {

  ScrollToTop();

  return (
    <Container>
      <PageBanner pagename={"About"} img={BACK} />
      <Main>
        <Left>
          <ImageContainer>
            <Image src={AboutImage} alt="About Us" />
          </ImageContainer>
        </Left>

        <Right>
          <Heading>
           Aspire To Inspire, New Blooming Buds School
          </Heading>
          <Description>
            Welcome to New Blooming Buds School, an esteemed educational institution situated in the vibrant neighborhood of Raghu Nagar in New Delhi. With a vision to nurture young minds and empower them with knowledge and skills, New Blooming Buds School stands as a symbol of quality education, holistic development, and a nurturing learning environment.
          </Description>

          <ExtraContainer>
            <Box>
              <BasicHeading>
                Our Vision
              </BasicHeading>

              <Description>
                Our vision is to create a nurturing and stimulating environment where every child can blossom and thrive. We envision a school that embraces the unique qualities of each child, fostering their holistic development and laying a strong foundation for their future
              </Description>
            </Box>

            <Box>
              <BasicHeading>
                Our Mission
              </BasicHeading>
              <Description>
                At New Blooming Buds School, our mission is to provide a joyful and enriching learning experience for young children, fostering their overall development and preparing them for a lifelong love of learning. We are committed to create a nurturing environment that encourages exploration, creativity, and self-discovery.              
              </Description>
            </Box>
          </ExtraContainer>


          <Button>
            Learn More Classes
          </Button>
        </Right>
      </Main>
      <Grassland>
        <GrasslandBody>
          <Card>
            <CardImageContainer>
              <CardImage src={STUDENT} />
            </CardImageContainer>

            <CardTitle>
              2,950+
            </CardTitle>
            <CardDesc>
              COMPLETED MOVINGS  
            </CardDesc>
          </Card>

          <Card>
            <CardImageContainer>
              <CardImage src={BOOK} />
            </CardImageContainer>

            <CardTitle>
              21 Yrs
            </CardTitle>
            <CardDesc>
              Years  
            </CardDesc> 
          </Card>

          <Card>
            <CardImageContainer>
              <CardImage src={BUILDING} />
            </CardImageContainer>

            <CardTitle>
              1
            </CardTitle>
            <CardDesc>
              Branch  
            </CardDesc> 
          </Card>

          <Card>
            <CardImageContainer>
              <CardImage src={TEACHER} />
            </CardImageContainer>
            <CardTitle>
              10:1
            </CardTitle>
            <CardDesc>
              RATIO OF TEACHERS - STUDENTS  
            </CardDesc> 
          </Card>
        </GrasslandBody>
      </Grassland>
      <AboutBanner />
    </Container>
  )
}

export default About