import React, { useState } from "react";
import styled from "styled-components";
import PageBanner from "../components/PageBanner";
import Pagination from "../components/Pagination";
import ScrollToTop from '../hooks/ScrollToTop';


//images
import BACK from "../images/Gallery-back.jpg";


const PhotoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  gap: auto;
  justify-content: center;
  width: 90%;
`;

const ImageContainer = styled.div`
  display: flex;
  margin: 10px;
  padding: 10px;
  border: 2px solid #f2f2f2;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  width: 250px;
  height: 250px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
`



const images = [
  //16 aug 2024
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723799050/bsprthuvgp3y7infoylb.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723800043/qzqmhyk02fb4ncfppqku.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723800076/rojjxj3v3gfx2rs0ng0n.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801050/kuft8hyiyhsynb0pwiuk.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801092/rwudpiahoadqr41msrnl.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801128/chvfbegi1nqphr42mmmr.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801145/wdirh7mbn7gyvo4iylhm.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801167/ugdbkbsdhgbyhlrjpu9x.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801187/vkn9wiyoznhtvssijaf4.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801205/hpcehxf4ymostpzuhrsu.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801296/nsfycbcucgvusugetsfn.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801312/larek3ntgap4ibm0lnm7.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801330/gma6gce9dspksa8k1l56.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801345/lmgn7htcfmei3ktxfoip.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801361/mhvgzfu71fnmoupuukfa.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801376/v6706w9aqxgruu5mxegz.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801390/mng3ndfyslxfsju5v5d6.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801403/uxo0koq9tqvtaokdgvm4.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801417/jwcz97bvfog0slwrhg2u.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801430/ucqqlttcxmnek69gf93x.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801448/vo0mgahxauhp8f4l3r7v.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801466/fcj1ekcqepsgodlblsqu.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801483/mwvombm65vtvfdpnre6k.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801499/xkia9iie0mw4ky0fi2ru.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801514/lmndmdxwnhhytlamoahn.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801529/blkcdarlqcdsv8fayto7.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801542/gybyzp2dwanftbep2c01.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801554/ai9jpdvq08sr8utsn7x7.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801570/mzmzd1dx1ncdkqo8c3sa.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801584/w217qzykhkxr8yknvmuh.jpg?q_auto",
  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723801598/nmplu9mzrj9g5mep1vom.jpg?q_auto",
  

  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720074907/qkwj09dzgghswoxjkqev.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720074932/yt71u3wgsbca3hbazmkt.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720074949/mawud4neoqvcb4t47io0.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720074975/lyonu0jgajmfb5ilaabl.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720074992/xdcsnvohrhrlqxkh4v4s.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075007/wfafkqdxtploacltqqz4.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075028/jr7p2ffmmxzqi595bkhu.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075046/qcbddcoavrje2ubljfod.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075065/tcdpsifm5rv6uj4qokk8.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075102/zfnb2rg5wo5q0abprb4e.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075118/lihv4z9o3tlygphykz4g.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075133/dgxowsj7qeoshdcytv1i.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075150/lu4g5jwlbq7ikg5akaef.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075168/qhkmwuo7cd9cbl3iy2ol.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075192/qsgyz7pud7wro9fmxipe.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075215/juiybvupkknlirhwybps.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075232/gktmbswr4hj0lj2twake.jpg?q_auto",

  "https://res.cloudinary.com/dxybawl7g/image/upload/v1720075254/v9itvzayjjpsj8kubikz.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712242689/oqgssrjx3r5jmkd7j6yu.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712242828/lag6ltwnso4izw2o1qou.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712242840/p2xqphc2rul7ec9qhmjz.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712242856/ud0cwgtr66q8am52o0k6.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712242872/ctpmiexczraocwxaejme.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712242888/khvuzzwfg0dxqtrijve7.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712242942/jhtnuslpkarohzsewt5h.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243146/gf8uvuvurotopojwrnfx.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243160/jyiyuhmo42qm4sd7mkwh.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243174/gwjaas9qgy4pjj2mhk7q.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243186/yvknjuxbmbiabktslxqe.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243198/wsebramo57kbyu8w2ari.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243212/w0tzugaghp6oeuabaanu.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243224/tagbq6jo4nhb1akfd71f.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243263/isv07byk1xnqwybnqnay.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243283/p2wrhipnrpljfby8oslz.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243295/idcj3eie0cram0kdfnyp.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243307/wqbllkz8neqxdsoodhrz.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243320/zv5ugo4hkzbjdahbpbup.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243331/beiwpdgpljrzegvwbrkr.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243343/ytargxn2zgoi8eantroe.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243353/jzi0rhysqdtei9fv1ota.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243364/clr1tvi0m6qiyj4pdvrr.jpg?q_auto",
  "https://res.cloudinary.com/dxybawl7g/image/upload/v1712243376/xvsfu1pwxzjtjg8c1tey.jpg?q_auto"
]


function Gallery({ postPerPage }) {

  ScrollToTop();
  const [currentPage, setCurrentPage] = useState(1);


  //Get current Post
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPost = images.slice(indexOfFirstPost, indexOfLastPost);
  console.log(currentPost);

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const previous = () => {
    let currpage = currentPage;
    if (currpage !== 1) setCurrentPage(currpage - 1);
  };

  const next = () => {
    let currpage = currentPage;
    let lastpage = Math.ceil(images.length / postPerPage);
    if (currpage !== lastpage) setCurrentPage(currpage + 1);
  };

  return (
    <>
      <PageBanner img={BACK} pagename={"Gallery"} />
      <PhotoContainer>
        {currentPost.map((image, index) => (
          <ImageContainer key={index}>
            <Image src={image} />
          </ImageContainer>
        ))}
      </PhotoContainer>
      <Pagination
        postPerPage={postPerPage}
        totalPost={images.length}
        paginate={paginate}
        previous={previous}
        next={next}
      />
    </>
  );
}

export default Gallery;