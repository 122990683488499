import React from "react";
import styled from "styled-components";
import PageBanner from "../components/PageBanner";
import ScrollToTop from '../hooks/ScrollToTop';
import TeacherInfoCard from "../components/TeacherInfoCard";

//images
import BACK from "../images/Teacher-back.jpg";
import image1 from "../images/Teachers/2.jpg";
import image2 from "../images/Teachers/5.jpg";
import image3 from "../images/Teachers/6.jpg";
import image4 from "../images/Teachers/7.jpg";
import PRINCIPAL from "../images/Teachers/main/principal.jpeg"
import VICE from "../images/Teachers/main/rekha-ma'am.jpeg"
import QUALIFIED from "../images/Teachers/main/jyoti'mam.jpeg"

const imageArr = [image1, image2, image3, image4];

const data = [
  {
    title: "Ms. POONAM CHAUHAN (PRINCIPAL)",
    desc: [
      "As Principal, I would like to personally welcome you to our NBBS family. We hope that your child’s journey with us will surely help in yielding fruitful results and envisage our shared dreams and to dream new.",
      "Our school has been approaching ahead by adding new features. An effort has been made here to present a comprehensive picture of this new session for your attention.",
      "India has a diverse cultural heritage, which frequently shows in our celebrations. The vibrant Indian culture serves as inspiration for our event organizing as well. From iridescent weddings and exciting birthday parties to sophisticated business meetings and understated educational seminars, we are skilled at arranging all different types of events and occasions.",
      "Mahatma Gandhi rightly said, “The best way to find yourself is to lose yourself in the service of others.” Firmly believing in our core values of courage, compassion, equality and integrity, we at NBBS, aim to nurture our students, so that they are well equipped to face the competitive society with value based knowledge and skills that have contributed towards the growth and vision in building global citizens and a conscientious humanity.",
      "Our children are our strength and inspiration. Each child is a fountain head of curiosity, which we recognize and respect.",
      "Keeping in mind that to teach is to touch a life forever, we believe in holistic education, that encompasses academics, co-curricular activities, sports education and life skills learning. Activities and events related to value education, environment, health education, culture and heritage, personality development are effectively woven in to the main fabric of school curriculum. Our children are intelligent, resilient, creative, imaginative, disciplined, respectful, ethical, and would remain dedicated to lifelong learning, with the nurturing they get from the school they would definitely become the future leaders and role models of society. God Bless you all !",
      "REGARDS",
      "POONAM CHAUHAN",
      "PRINCIPAL",
      "CONTACT NO. - 9818609552"

    ]
  },
  {
    title:"Ms. REKHA SHARMA (VICE PRINCIPAL)",
    desc: [
      "Dear Parents and Guardians,",
      "I am honoured to welcome you to New Blooming Buds School, where we strive to create a nurturing and stimulating learning environment for our students. Our school's motto, \"Aspire to inspire\", encapsulates our commitment for inspiring our students to reach their full potential and become the best versions of themselves.",
      "At New Blooming Buds School, we provide a comprehensive educational experience that caters to the unique needs of each child. Our curriculum is designed to foster critical thinking, creativity, and problem-solving skills, and is aligned with state and national standards. Our dedicated faculty and staff are passionate about providing a high-quality education to our students, and we ensure that they receive the support and resources they need to excel.",
      "We believe that learning should be an enjoyable experience that encourages children to be curious, ask questions, and actively participate in their education. Our play-based learning approach provides children with opportunities to explore, experiment, and discover while developing their social, emotional, and cognitive skills. We also offer a range of extracurricular activities, including sports, music, art, and declamation, to provide children with opportunities to explore their interests and develop their talents. At New Blooming Buds School, we recognize the importance of parental involvement in a child's education and development. We encourage parents to attend school events, parent-teacher conferences, and workshops.",
      "Our school is committed for promoting a safe and inclusive learning environment that fosters respect, understanding, and appreciation for diversity. We celebrate diversity in all its forms and believe that every child deserves a safe and welcoming learning environment where they can thrive and feel valued. In conclusion, I would like to express my gratitude for entrusting your child's education to us at New Blooming Buds School. We are committed for providing a high-quality education in a nurturing and stimulating environment that inspires our students to aspire to their full potential.",
      "REGARDS",
      "REKHA SHARMA",
      "VICE PRINCIPAL",
      "QUALIFICATION - M.A, B.ed and NTT"
    ]
  },
  {
    title:"श्रीमती ज्योति आहूजा",
    desc: [
      "प्रिय माता-पिता और अभिभावक,",
      "हमारे विद्यालय का उद्देश्य हर बच्चे को मज़बूत , परिपक्व , दयालु और बुद्धिमान वयस्कों के रूप में विकसित करने के लिए मानक स्थापित करने को समर्पित है I हम समग्र शिक्षा में विश्वास करते हैं जिसमें शिक्षा और जीवन – कौशल शामिल हैं I हमारा प्रयास अत्याधुनिक बुनियादी ढांचे और एक मज़बूत पाठ्यक्रम के बीच संतुलन बनाना है I मूल्य शिक्षा , पर्यावरण , खेल , प्रदर्शन कला , संस्कृति और विरासत , व्यक्तित्व विकास से सम्बंधित , गतिविधियों और घटनाओं को प्रभावी ढंग से स्कूल पाठ्यक्रम के मुख्य ताने बाने में बुना जाता है I न्यू ब्लूमिन्ग बड्स परिवार में आपका अभिनन्दन करते हुए यह आशा करते हैं की हर बच्चा कुशल , ईमानदार और इस प्रतिस्पर्धी समाज का सामना करने के लिए तैयार होगा |",
      "नमस्कार",
      "श्रीमती ज्योति आहूजा",
      "शैक्षणिक प्रभारी",
      "न्यू ब्लूमिन्ग बड्स स्कूल"
    ]  
  }
  ];

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const GridContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;


const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 80%;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 20%;
  max-width: 20%;

  @media (max-width: 480px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

const Title = styled.h1`
  font-size: 2.5rem;
`;

function Teacher() {
  ScrollToTop();
  return (
    <Container>
      <PageBanner img={BACK} pagename={"Teacher"} />
      <TeacherInfoCard src={PRINCIPAL} data={data[0]} />
      <TeacherInfoCard src={VICE} data={data[1]} />
      <TeacherInfoCard src={QUALIFIED} data={data[2]} />
      <Title>Our Staff</Title>
      <GridContainer>
      <Grid>
        {imageArr.map((image, index) => (
          <ImageContainer key={index}>
            <Image src={image} alt="teacher" />
          </ImageContainer>
        ))}
      </Grid>
      </GridContainer>
    </Container>
  );
}

export default Teacher;
