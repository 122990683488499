import styled, { keyframes } from 'styled-components';

//Images
import BEE from '../images/bee.png'
import CHILD from '../images/child-man.png'

//Icons
import BULB from '../icons/bulb.png'
import FOOTBALL from '../icons/football.png'
import DESK from '../icons/desk.png'
import TOYCART from '../icons/toycart.png'


// Define the spin animation
const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;


const Container = styled.div`
  display: flex;
  margin: 70px 0px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Top = styled.div` 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

const Title = styled.h1`
  font-family: "Schoolbell", cursive;
  color: #fe4880;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 3px;
  margin: 0px;
`

const Heading = styled.h1`
  font-size: 34px;
  font-weight: 300;
  color: #333;
  margin: 0px;
`

const Desc = styled.p`
  font-size: 14px;
  width: 40%;
  color: #696969;
  font-weight: 400;
  text-align: center;
  margin: 0;
  line-height: 30px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  width: 90%;

  //breakpoint for mobile
  @media (max-width: 480px) {
    flex-direction: column;
    margin-top: 10px;
  }
`

const Part = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 100%;
    gap: 20px;
 }
`

const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  width: 450px;
  height: 150px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
`

const CardPart = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

const CardHeading = styled.h1`
  font-size: 20px;
  color: #000000;
  font-weight: 500;
  margin: 0;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 16px;
    text-align: center;
  }
`

const CardDesc = styled.p`
  font-size: 14px;
  color: #000000;
  font-weight: 300;
  text-align: center;
  margin: 0;
`

const CardImageContainer = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
  margin: 30px;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    width: 85px;
    height: 85px;
    border-radius: 50%;
    border: 2px dashed #fe4880;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${spin} 10s infinite linear;
    animation-play-state: paused; /* Pause the animation by default */
  }

  &:hover::before {
    animation-play-state: running; /* Start the animation on hover */
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 99%;
  background-color: #fe4880;
  border-radius: 50%;
`


const CardImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;


const Image = styled.img`
  width: 100px;
  height: 40px;
  object-fit: contain;
`


const MainImage = styled.img`
  width: 150%;
  height: 150%;
  object-fit: cover;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 80%;
    height: 80%;
  }
`

function Activities() {
  return (
    <Container>
      <Top>
        <Title>THINGS FOR KIDS</Title>
        <Heading>Kids Activities</Heading>
        <Desc>
          Infant classroom offers strategies for building positive relationships, 
          helping children develop self-regulation and responding to challenging behaviors.
        </Desc>

        <Image src={BEE} alt="bee" />
      </Top>
      <Bottom>

        <Part>
          {/*Card 1*/}
          <Card>
            <CardPart>
              <CardHeading>Creative Activities</CardHeading>
              <CardDesc>
                Our school unleashes imagination, artistic expression, and innovation, nurturing the creative potential of young minds in a fun and supportive environment.
              </CardDesc>
            </CardPart>
            <CardPart>
              <CardImageContainer>
                <ImageContainer>
                  <CardImage src={BULB} alt="bulb" />
                </ImageContainer>
              </CardImageContainer>
            </CardPart>
          </Card>
          
          {/*Card 2*/}
          <Card>
            <CardPart>
              <CardHeading>Sport Activities</CardHeading>
              <CardDesc>
              Our school promotes physical fitness, teamwork, and sportsmanship, empowering young children to develop their motor skills and a love for an active lifestyle              </CardDesc>
            </CardPart>
            <CardPart>
              <CardImageContainer>
                <ImageContainer>
                  <CardImage src={FOOTBALL} alt="FOOTBALL" />
                </ImageContainer>
              </CardImageContainer>
            </CardPart>
          </Card>
        </Part>

        <Part>
          <MainImage src={CHILD} alt="CHILD" />
        </Part>

        <Part>
          {/*Card 3*/}
          <Card>
            <CardPart>
              <CardImageContainer>
                <ImageContainer>
                  <CardImage src={TOYCART} alt="TOYCART" />
                </ImageContainer>
              </CardImageContainer>
            </CardPart>
            <CardPart>
              <CardHeading>Regular Health Check Ups</CardHeading>
              <CardDesc>
                New Blooming Buds School nurtures healthy futures with regular health checkups for its young blossoms
              </CardDesc>
            </CardPart>
          </Card>
          
          {/*Card 4*/}
          <Card>
          <CardPart>
              <CardImageContainer>
                <ImageContainer>
                  <CardImage src={DESK} alt="DESK" />
                </ImageContainer>
              </CardImageContainer>
            </CardPart>
            <CardPart>
              <CardHeading>Live activities</CardHeading>
              <CardDesc>
              New Blooming Buds School thrives with vibrant live activities that ignite the joy of learning and foster holistic growth in its young students.              </CardDesc>
            </CardPart>
          </Card>

        </Part>
      </Bottom>
    </Container>
  )
}

export default Activities