import {useRef} from 'react'
import styled from 'styled-components'
import ScrollToTop from '../hooks/ScrollToTop';


//images
import CardIcon1 from '../images/card-icon-1.png';
import CardIcon2 from '../images/card-icon-2.png';
import CardIcon3 from '../images/card-icon-3.png';
import CardIcon4 from '../images/card-icon-4.png';

//Components
import Banner from '../components/Banner';
import Card from '../components/Card';
import HomeAbout from '../components/HomeAbout';
import HomeInfo from '../components/HomeInfo';
import Activities from '../components/Activities';
import Testimonials from '../components/Testimonials';
import HomeGallery from '../components/HomeGallery';
import Enroll from '../components/Enroll';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CardContainer = styled.div`
  display: flex;
  margin: 10px 0px 50px 0px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;

  //breakpoint for mobile
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const info = {
  1: "Our school blends education and fun, where children embark on exciting adventures, fostering curiosity, creativity, and a love for learning through interactive.",
  2: "Our school brings the world to children's fingertips, opening doors to knowledge, creativity, and exploration through innovative digital platforms.",
  3: "Our school cultivates a nurturing and encouraging environment, fostering self-esteem, resilience, and a love for learning, empowering children to reach their full potential.",
  4: "Our school celebrates the dedication and passion of our educators, who inspire and guide young minds, igniting a lifelong love for learning and creating a positive impact."
}

function Home() {



  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };


  ScrollToTop();

  return (
    <Container>
      <Banner handleScroll={scrollToBottom}/>
      <CardContainer>
        <Card color={"#ff6665"} borderColor={"#F53C3B"} icon={CardIcon1} title={"Learn & Play"} info={info[1]}/>
        <Card color={"#fec000"} borderColor={"#DDA702"} icon={CardIcon2} title={"Virtual Learning"} info={info[2]}/>
        <Card color={"#abcd51"} borderColor={"#93B43D"} icon={CardIcon3} title={"Positive Learning"} info={info[3]}/>
        <Card color={"#1BB9FF"} borderColor={"#0391D0"} icon={CardIcon4} title={"Great Teacher"} info={info[4]}/>
      </CardContainer>
      <HomeAbout />
      <HomeInfo />
      <Activities ref={scrollRef}/>
      <Testimonials />
      <HomeGallery />
      <Enroll />
    </Container>
  )
}

export default Home