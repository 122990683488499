import styled from 'styled-components'

//photos
import GOOGLE from '../images/google.jpeg'


const Container = styled.div`
  width: 350px;
  height: 350px;
  gap: 10px;
  font-size: 14px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.6);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  border: 1px solid #000000;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 300px;
    height: 300px;
  }
`

const About = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
`

const CardImageContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #df1f1f;
  transition: all 0.5s;
  backface-visibility: hidden;
`


const CardImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const Name = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0px;
  padding: 10px 0px;
`

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin: 0px;
  padding: 10px 0px;
  letter-spacing: 5px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 16px;
  }
`

const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin: 0px;
  padding: 10px 0px;
  line-height: 1.6;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 12px;
  }
`


function Reviews({name, heading, description}) {
  return (
      <Container>
        <About>
          <CardImageContainer>
            <CardImg src={GOOGLE} alt="Google" />
          </CardImageContainer>
          <Name>{name}</Name>
        </About>

        <CardText>
          <Heading>{heading}</Heading>
          <Description>
            {description.length > 400 ? description.substring(0, 300) + "......................" : description}
          </Description>
        </CardText>
      </Container>
  )
}

export default Reviews