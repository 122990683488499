import styled from 'styled-components'
import { Link } from 'react-router-dom'

//icons
import CallIcon from "../icons/Call-white.svg"
import EmailIcon from "../icons/Mail-white.svg"
import LocationIcon from "../icons/Location-white.svg"
import ClockIcon from "../icons/Clock-white.svg"


const Container = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  background-color: #351c5a;
  height: 550px;
  width: 100%;
  bottom: 0;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
    height: 100%;
    padding: 20px 0px;
  }
`

const Part = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const LogoContainer = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Heading = styled.h1`
  color: white;
  font-size: 20px;
  font-weight: 500;
`

const DescriptionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`

const Description = styled.p`
  margin: 0px;
  width: 280px;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
`

const Map = styled.div`  
  width: 280px;
  height: 280px;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`



function Footer() {

  return (
    <Container>
      <Part>
        <LogoContainer>
          <Logo src={'https://res.cloudinary.com/dtpb5qjjf/image/upload/f_auto,q_auto:low/v1710040067/logo.5ecc2314d76c5bca63fd_tvslqr.png'} alt="logo" />
        </LogoContainer>
        <Description>
          New Blooming Buds School is a nurturing and inspiring educational school, cultivating a love for learning and providing a strong foundation for young children to flourish and bloom.
        </Description>
      </Part>

      <Part>
        <Heading>Contact Us</Heading>

        <DescriptionContainer>
          <Icon src={CallIcon} alt="Call" />
          <Description>9650674928 || 8448557569 || 9818609552</Description>
        </DescriptionContainer>

        <DescriptionContainer>
          <Icon src={EmailIcon} alt="Email" />
          <Description>poonam.chauhan1170@gmail.com</Description>
        </DescriptionContainer>

        <DescriptionContainer>
          <Icon src={LocationIcon} alt="Location" />
          <Description>RZ-108A,Street no.3,Raghu Nagar,Shiv block,Pankha road,New Delhi-45</Description>
        </DescriptionContainer>

        <DescriptionContainer>
          <Icon src={ClockIcon} alt="Clock" />
          <Description>
            Mon - Fri(8:30am - 1:30pm) Summer,
            Mon - Fri(9:00am - 2:00pm) Winter
          </Description>
        </DescriptionContainer>
      </Part>

      <Part>
        <Heading>Quick Links</Heading>
        <Link to="/">
          <Description>Home</Description>
        </Link>

        <Link to="/about-us">
          <Description>About Us</Description>
        </Link>
        
        <Link to="/facilities">
          <Description>Facilities</Description>
        </Link>

        <Link to="/gallery">
          <Description>Gallery</Description>
        </Link>

        <Link to="/teacher">
          <Description>Teacher</Description>
        </Link>

        <Link to="/contact">
          <Description>Contact</Description>
        </Link>

      </Part>

      <Part>
        <Heading>Address</Heading>
        <Map>
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.6724058714376!2d77.08684837532547!3d28.609602985121874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d04aca4d0de05%3A0x53a2c6457eb30534!2sNew%20Blooming%20Buds%20School!5e0!3m2!1sen!2sin!4v1710651205219!5m2!1sen!2sin" 
            width="280" 
            height="280" 
            style={{border: "0"}} 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
            >
            </iframe>
        </Map>
      </Part>
    </Container>
  )
}

export default Footer