import styled from 'styled-components'

//Images
import BEE from '../images/bee.png'
import PhotosGallery from './PhotosGallery'

const Container = styled.div`
  display: flex;
  margin: 20px 0px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
`

const Top = styled.div` 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

const Title = styled.h1`
  font-family: "Schoolbell", cursive;
  color: #fe4880;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 3px;
  margin: 0px;
`

const Heading = styled.h1`
  font-size: 34px;
  font-weight: 300;
  color: #333;
  margin: 0px;
`

const Image = styled.img`
  width: 100px;
  height: 40px;
  object-fit: contain;
`


const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 80%;
`

function HomeGallery() {
  return (
    <Container>
      <Top>
        <Title>EXPLORE NBBS</Title>
        <Heading>See Our Gallery</Heading>
        <Image src={BEE} alt="BEE" />
      </Top>
      <Bottom>
        <PhotosGallery />
      </Bottom>
    </Container>
  )
}

export default HomeGallery