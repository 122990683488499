import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 1rem;
  margin: 10px 0px;
`;


const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: centre;
  align-items: centre;
  gap: 1rem;
`;

const List = styled.button`
  text-align: centre;
  color: #000000;
  border: 1px solid black;
  cursor: pointer;
`;

const Button = styled.button`
  color: #000000;
`;



function Pagination({ postPerPage, totalPost, paginate, previous, next }) {
  const pageNumbers = [];
  for (let i = 1; i < Math.ceil(totalPost / postPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <Container>
      <ListContainer>
        <Button onClick={() => previous()}>PREV</Button>
          {pageNumbers.map((numbers) => (
            <List onClick={() => paginate(numbers)}>{numbers}</List>
          ))}
        <Button onClick={() => next()}>NEXT</Button>
      </ListContainer>
    </Container>
  );
}

export default Pagination;