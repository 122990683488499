import styled from 'styled-components'
import Photo from './Photo'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  gap: 10px;
  flex-wrap: wrap;
`

const images = {
  1: "https://res.cloudinary.com/dcws3edcw/image/upload/v1723790807/bxfyuwngvofg7hn4emwh.jpg?q_auto",
  2:  "https://res.cloudinary.com/dcws3edcw/image/upload/v1723790782/p3n4sykezierrlwm9zzq.jpg?q_auto",  
  3: "https://res.cloudinary.com/dcws3edcw/image/upload/v1723790765/kn9xbwm9vrztsjgymhpy.jpg?q_auto",
  4: "https://res.cloudinary.com/dcws3edcw/image/upload/v1723790749/buskdl3gtqj9t38aatoa.jpg?q_auto",
  5: "https://res.cloudinary.com/dcws3edcw/image/upload/v1723790715/y7kydvmznrkorubhptnq.jpg?q_auto",
  6: "https://res.cloudinary.com/dcws3edcw/image/upload/v1723790594/qumkg3kakpnltkmxiltf.jpg?q_auto",
}


function PhotosGallery() {  
  return (
    <Container>
      <Photo link={images[1]}/>
      <Photo link={images[2]}/>
      <Photo link={images[3]}/>
      <Photo link={images[4]}/>
      <Photo link={images[5]}/>
      <Photo link={images[6]}/>
    </Container>
  )
}

export default PhotosGallery