import styled, { keyframes } from 'styled-components'

//Images
import ABOUTBANNER from '../images/about-banner.png'


//Icons
import KINDERGARTEN from "../icons/kindergarten.png"
import PLAYGROUND from "../icons/playground.png"
import PALETTE from "../icons/palette.png"
import BOOK from "../icons/books.png"


// Define the spin animation
const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const Container = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${ABOUTBANNER});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
` 

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
`

const CardBefore = styled.div`  
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  `  

const CardImageContainer = styled.div`
  display: flex;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bgcolor ? props.bgcolor : '#f2f2f2'};
  position: relative;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px dashed ${props => props.bgcolor ? props.bgcolor : '#f2f2f2'};
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${spin} 10s infinite linear;
    animation-play-state: paused; /* Pause the animation by default */
  }

  &:hover::before {
    animation-play-state: running; /* Start the animation on hover */
  }
`;


const CardImage = styled.img`
  width: 50%;
  height: 50%;
  object-fit: contain;
`

const CardTitle = styled.h3`
  color: #000000;
  font-size: 22px;
  letter-spacing: 2px;
  text-align: center;
`


function AboutBanner() {
  return (
    <Container>
      <Main>
        <Card>
          <CardBefore>
            <CardImageContainer bgcolor={'#f5e0df'}>
              <CardImage src={KINDERGARTEN} />
            </CardImageContainer>
            <CardTitle>Happy Environments</CardTitle>
          </CardBefore>
        </Card>

        <Card>
          <CardBefore>
          <CardImageContainer bgcolor={'#eee3ca'}>
            <CardImage src={BOOK}  />
          </CardImageContainer>
          <CardTitle>Active Learning</CardTitle>
          </CardBefore>
        </Card>

        <Card>
          <CardBefore>
          <CardImageContainer bgcolor={"#edf1d5"}>
            <CardImage src={PALETTE} />
          </CardImageContainer>
          <CardTitle>Creative Lessons</CardTitle>
          </CardBefore>
        </Card>

        <Card>
          <CardBefore>
          <CardImageContainer bgcolor={"#cbebf8"}>
            <CardImage src={PLAYGROUND} />
          </CardImageContainer>
          <CardTitle>Extracurricular Activities</CardTitle>
          </CardBefore>
        </Card>
      </Main>
    </Container>
  )
}

export default AboutBanner