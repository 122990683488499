import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

//images
import BACK from "../images/navbar-back.png"

//icons

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fef100;
  height: 120px;
  width: 100vw;
  background-image: url(${BACK});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

`


const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 100px;
`
const Logo = styled.img`
  height: 100px;
  width: 100px;
`


const MenuContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-right: 100px;  
` 

const MenuItem = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: black;
  background-color: ${props => props.color ? props.color : "white"};
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`

function Navbar() {

  const navigate = useNavigate();

  const handleMenu = (path) => {
    navigate(path);
  }

  return (
    <Container>
      <LogoContainer>
        <Logo src= {'https://res.cloudinary.com/dtpb5qjjf/image/upload/f_auto,q_auto:low/v1710040067/logo.5ecc2314d76c5bca63fd_tvslqr.png'} />
      </LogoContainer>

      <MenuContainer>
          <MenuItem onClick={() => handleMenu('/')} color={"#FFC2C2"} >Home</MenuItem>
          
          <MenuItem onClick={() => handleMenu("/about-us")} color={"#C2FFD0"}>About Us</MenuItem>

          <MenuItem onClick={() => handleMenu('/facilities')} color={"#C2CCFF"}>Facilities</MenuItem>

          <MenuItem onClick={() => handleMenu('/gallery')} color={"#FFC2F2"}>Gallery</MenuItem>

          <MenuItem onClick={() => handleMenu('/teacher')} color={"#FCFF82"}>Teacher</MenuItem>

          <MenuItem onClick={() => handleMenu('/contact')} color={"#C2FFFF"}>Contact</MenuItem>

          <MenuItem onClick={() => handleMenu('/enroll')} color={"#C2FFFF"}>Enroll</MenuItem>
      </MenuContainer>
    </Container>
  )
}

export default Navbar