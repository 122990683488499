import styled from 'styled-components'

const Container = styled.div`
  width: 370px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-family: "Fredoka", sans-serif;
  color: #271343;
  border: 1px solid #dcdcdc;


`

const ImageContainer = styled.div`
  width: 100%;
  height: 280px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  
  &::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 10px;
    background-size: 20px 20px;
    background-image:
    radial-gradient(circle at 10px -5px, transparent 12px, #ffffff 13px);
  }

  &::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 15px;
    background-size: 20px 20px;
    background-image:
    radial-gradient(circle at 10px 5px, transparent 12px, #ff0000 13px);
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  object-fit: cover;
`

const TitleContainer = styled.div`
  width: 70%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  margin: 0px;
`




function FacilitiesCard({img, title}) {
  return (
    <Container>
      <ImageContainer>
        <Image src={img} />
      </ImageContainer>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
    </Container>
  )
}

export default FacilitiesCard