import styled from 'styled-components'

//Components
import Reviews from './Reviews'


//Images
import BEE from '../images/bee.png'


const Container = styled.div`
  display: flex;
  margin: 70px 0px 20px 0px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
`

const Top = styled.div` 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 480px) {
    gap: 20px;
    width: 100%;
  }
`

const Title = styled.h1`
  font-family: "Schoolbell", cursive;
  color: #fe4880;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 3px;
  margin: 0px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 24px;
  }
`

const Heading = styled.h1`
  font-size: 34px;
  font-weight: 300;
  color: #333;
  margin: 0px;

  //breakpoint for mobile
  @media (max-width: 480px) {
    font-size: 24px;
  }
`

const Desc = styled.p`
  font-size: 16px;
  color: #696969;
  font-weight: 400;
  text-align: center;
  margin: 0;
  line-height: 30px;
  
`

const Image = styled.img`
  width: 100px;
  height: 40px;
  object-fit: contain;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;


`

const ReviewContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

    //breakpoint for mobile
    @media (max-width: 480px) {
    flex-direction: column;
  }
`

const Main  = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 90%;
  height: 100%;
  overflow-x: scroll;
`


const reviews = {
  1: {
    name: "Abhishek Kumar",
    heading: "⭐️⭐️⭐️⭐️⭐️",
    description: "Thrilled with our experience at New Blooming Buds School. The staff is incredibly caring and dedicated, providing a nurturing environment where my niece thrives. The curriculum is engaging, the facilities are top-notch, and communication with parents is excellent. It's more than a school—it's a second home where children blossom. Highly recommend!!"
  },
  2: {
    name: "Mansa verma",
    heading: "⭐️⭐️⭐️⭐️⭐️",
    description: "The teachers here are incredibly caring and dedicated in creating a nurturing environment where kids can thrive. The school's focus on both academics and character development is remarkable.They offer a diverse range of extracurricular activities, ensuring a well-rounded education. It is Highly recommended to any parent looking for an excellent education for their little ones."  },
  3: {
    name: "Kartik",
    heading: "⭐️⭐️⭐️⭐️⭐️",
    description: "Delighted with \"New Blooming Buds School\" The teachers are fantastic—so caring and dedicated to each child's development. The curriculum is engaging. Thankyou !!" }
}

function Testimonials() {

  return (
    <Container>
      <Top>
        <Title>WHAT DO PARENTS SAY ABOUT US</Title>
        <Heading>Testimonials</Heading>
        <Desc>
          Let's explore what Parents have to say about us. Let us know what you think of today’s newsletter and what you’d like to.
        </Desc>

        <Image src={BEE} alt="bee" />
      </Top>
      <Bottom>
        <Main>
          <ReviewContainer>
            <Reviews name={reviews[1].name} heading={reviews[1].heading} description={reviews[1].description}/>
            <Reviews name={reviews[2].name} heading={reviews[2].heading} description={reviews[2].description}/>
            <Reviews name={reviews[3].name} heading={reviews[3].heading} description={reviews[3].description}/>
          </ReviewContainer>
        </Main>
      </Bottom>
    </Container>

  )
}

export default Testimonials