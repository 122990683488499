import { useState } from 'react'
import styled from 'styled-components'
import ScrollToTop from '../hooks/ScrollToTop'


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 20px;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  height: 100%;

  @media (max-width: 480px) {
    width: 100%;
  }
`

const Heading = styled.h1`
  font-size: 32px;
  color: #333;
  margin: 20px;
  padding: 20px;
  font-weight: 500;
  text-align: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`

const Part = styled.div`
  display: flex;
  justify-content: space-around;
  gap: ${(props) => props.gap || "20px"};
  width: 100%;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;


`

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ffffff;
  background-color: #F2F2F2;
  width: ${(props) => props.width || "400px"};
  height: 40px;
  border-radius: 2px;
  font-size: 22px;

  @media (max-width: 480px) {
    width: 100%;
    padding: 8px 0px;
  }
`

const Label = styled.label`
  font-size: 16px;
  color: #333;
`

const TextArea = styled.textarea`
  padding: 10px 0px;
  border: 1px solid #ffffff;
  background-color: #F2F2F2;
  width: 100%;
  height: 120px;
  border-radius: 2px;
  font-size: 22px;
`

const Button = styled.button`
  padding: 10px 20px;
  width: 120px;
  height: 50px;
  background-color: #fe4880;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 22px;
  cursor: pointer;
`


function Enroll() {
  ScrollToTop();


  const [formData, setFormData] = useState({
    parentName: "",
    address: "",
    mobile: "",
    email: "",
    childName: "",
    childAge: "",
    childClass: "",
    query: ""
  })

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if(formData.mobile.length !== 10) {
      alert("Mobile number should be of 10 digits")
      return
    }

    const url = "https://bl-ljv9.onrender.com/api/form/join"

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
    .then((res) => res.json())
    .then((data) => {
      alert(data.message)
      setFormData({
        parentName: "",
        address: "",
        mobile: "",
        email: "",
        childName: "",
        childAge: "",
        childClass: "",
        query: ""
      })
    }).catch((err) => {
      console.log(err)
    })

  }


  return (
    <Container>
      <Main>
        <Heading>Query Form</Heading>

        <Form onSubmit={handleSubmit}>
          <Part>
            <InputContainer>
              <Label>Parent’s (Mother/Father) name</Label>
              <Input type="text" value={formData.parentName} name='parentName' onChange={handleChange} required />
            </InputContainer>

            <InputContainer>
              <Label>Address</Label>
              <Input type="text" value={formData.address} name='address' onChange={handleChange} required />
            </InputContainer>
          </Part>

          <Part>
            <InputContainer>
              <Label>Mobile no.</Label>
              <Input type="text" value={formData.mobile} name='mobile' onChange={handleChange} required />
            </InputContainer>

            <InputContainer>
              <Label>Email</Label>
              <Input type="text" value={formData.email} name='email' onChange={handleChange} />
            </InputContainer>
          </Part>

          <Part>
            <InputContainer>
              <Label>Child’s Name</Label>
              <Input type="text" value={formData.childName} name='childName' onChange={handleChange} required />
            </InputContainer>

            <Part gap="10px">
              <InputContainer>
                <Label>Child’s Age</Label>
                <Input type="Number" width="180px" value={formData.childAge} name='childAge' onChange={handleChange} required />
              </InputContainer>

              <InputContainer>
                <Label>Class</Label>
                <Input type="text" width="180px" value={formData.childClass} name='childClass' onChange={handleChange} required />
              </InputContainer>
            </Part>
          </Part>

          <Part>
            <InputContainer>
              <Label>Query</Label>
              <TextArea value={formData.query} name='query' onChange={handleChange} />
            </InputContainer>
          </Part>

          <Part>
            <Button>Submit</Button>
          </Part>
        </Form>
      </Main>
    </Container>
  )
}

export default Enroll