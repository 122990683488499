import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 160px;
  align-items: center;
  justify-content: center;
  background-color: #abcd51;

  @media (max-width: 480px) {
    flex-direction: column;
    height: 100%;
    padding: 20px 0px;
    gap: 30px;
  }
`

const Part = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;

  @media (max-width: 480px) {
    gap: 30px;
  }

  h1 {
    font-size: 24px;
    color: white;
    margin: 0;

    @media (max-width: 480px) {
      text-align: center;
      font-size: 22px;
    }
  }

  p {
    font-size: 16px;
    color: white;
    margin: 0;

    @media (max-width: 480px) {
      text-align: center;
      font-size: 14px;
    }
  }
`


const Button = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff6665;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #F53C3B;
  }
`



function Enroll() {

  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/enroll')
  }
  
  return (
    <Container>
      <Part>
        <h1>
          HOW TO ENROLL YOUR CHILD TO A CLASS?
        </h1>
        <p>
          Interested in good school education for your child? Our School is the right decision!
        </p>
      </Part>
      <Button onClick={handleClick}>
        ENROLL NOW
      </Button>
    </Container>
  )
}

export default Enroll